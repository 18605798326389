/* #wave_canvas {
  -webkit-transition: -webkit-transform 20s ease-in-out;
}
.animate{
  transform : translate(-1221px, 0px);
} */

/* #wave_content {
  position: relative;
  margin: 0 auto;
  z-index: 51;
  top: 0;
  cursor: pointer;
} */

/* #separator {
  position: absolute;
  background: #555;
  height: 1px;
  top: 30px;
  left: 0px;
} */

/* #cursor {
  position: absolute;
  background: #FFF;
  width: 3px;
  top: 0;
} */

#wave_area1 {
  position: absolute;
  top: 0;
  left: 0;
}

#wave_area2 {
  display: none;
  position: absolute;
  top: 30px;
  left: 0;
}
