/* body.dragging: {
    cursor: copy !important;
 }, */

.library {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
}

.eaction:hover {
  text-decoration: underline;
  cursor: pointer;
}

.item {
  background: #373737;
  /* border: 1px solid black; */
  box-sizing: border-box;
  color: #fff;
  font-size: 12px;
  font-family: inherit;
  user-select: none;

  /* center align text */
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

/* .item.is-dragging {
  background: #515b7d;
  border-color: MediumSeaGreen;
  box-shadow: 0px 0px 2px rgb(8, 58, 30), 0px 0px 10px MediumSeaGreen;
} */

.tag {
  position:absolute;
  left:-10%;
  display: inline-block;
  margin: 0 2px;
  font-weight: 400;
  text-align: center;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  /*cursor: pointer;*/
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none !important;
  box-shadow: none !important;
  border-color: #2f353b;
  color: #FFFFFF;
  background-color: #2f353b;
  border-radius: 25px !important;
}

.show {
  display: block !important;
}
.hide {
  display: none !important;
}
.notification {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;

  position: fixed;
  bottom: 15px;
  left: 15px;
  -webkit-transition-delay: 2000ms;
  -moz-transition-delay: 2000ms;
  -o-transition-delay: 2000ms;
  transition-delay: 2000ms;

  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
