.CardBP .fa-play-circle {
  display: none;
}

.CardBP:hover .fa-play-circle {
  display: block !important;
  cursor: pointer;
}

.fa-play-circle {
  font-size: 30px;
}

/* .fa-play-circle:hover {
  font-size: 35px !important;
} */

.CardBP .oo {
  display: none !important ;
}

.CardBP:hover .oo {
  display: block !important;
}
