.search-box {
  background-color: #1d1d1f;
  width: 80%;
  height: 20px;
  position: relative;
  border-bottom: 1px solid #2d2d2d;
}

.search-icon {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
  width: 15px;
  height: 15px;
  color: white;
  font-size: 0.8em;
  padding: 0px 5px;
}

.search-input {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 84%;
  height: 20px;
  color: white;
  margin-left: 5px;
  /* float: right; */
  background-color: transparent;
  border: 0px;
  font-size: 0.8em;
  position: absolute;
  outline: 0px;
}
